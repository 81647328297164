<template>
    <v-app>
        <v-content class="container align-start px-5">
            <h1 class="mb-5 mt-5">
                Reparaciones
            </h1>
            <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
            <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer>
          <!-- this dialog is used for both create and update -->
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                        <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
                            Nuevo  
                            <v-icon small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </div>
                  </template>
                  <v-card>
                    <v-card-title>
                        <span v-if="editedItem.id">Editar</span>
                        <span v-else>Crear</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-text-field outlined :rules="rules" v-model="editedItem.Matrícula" label="Matrícula"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field outlined v-model="editedItem.Cliente" label="Cliente"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field outlined v-model="editedItem.Marca" label="Marca"></v-text-field>
                          </v-col>
                            <v-col cols="12" sm="4">
                            <v-text-field outlined v-model="editedItem.Modelo" label="Modelo"></v-text-field>
                          </v-col>
                                                      <v-col cols="12" sm="4">
                            <v-text-field outlined v-model="editedItem.Cód_avería" label="Cód. avería"></v-text-field>
                          </v-col>
                                                      <v-col cols="12" sm="12">
                            <v-textarea outlined :rules="rules" v-model="editedItem.Avería_Descripción" label="Avería (Descripción)"></v-textarea>
                          </v-col>
                            <v-col cols="12" sm="12">
                            <v-textarea outlined :rules="rules" v-model="editedItem.Solución" label="Solución"></v-textarea>
                          </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="showEditDialog()">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="saveItem(editedItem)">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
</v-spacer>
      <v-col cols="12" sm="6" md="2">
         <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field></v-col>
    </v-card-title>
                <v-data-table 
                :headers="headers" 
                :items="items" 
                :search="search"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                locale= "es-ES"
                no-data-text= "No hay resultados"
                no-results-text= "No hay resultados para los criterios de búsqueda aplicados."
                @page-count="pageCount = $event"
                mobile-breakpoint="800"
                sort-by= "id"
                class="elevation-0">
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="text-truncate">
                          <v-icon
                            small
                            class="mr-2"
                            @click="showEditDialog(item)"
                            color="primary" 
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            @click="deleteItem(item)"
                            color="red" 
                          >
                            mdi-delete
                          </v-icon>
                      </div>
                    </template>

                </v-data-table>
                <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
        <v-col
          cols="12"
          sm="6"
          md="1"
        >
      <v-text-field
        :value="itemsPerPage"
        label="Elementos pág."
        type="number"
        min="-1"
        max="50"
        @input="itemsPerPage = parseInt($event, 10)"
      ></v-text-field> </v-col>
    </div>
                
            </v-card>
        </v-content>
    </v-app>
</template>

<script>
import axios from 'axios'
const apiToken = "keyrjswPVxmoBUNnT"
const airTableApp = "appYaKuy9VvzNhDU9"
const airTableName = "Averías"

  export default {
data () {
    return {
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
            //{ text: 'Id', value: 'id' },
            { text: 'Matrícula', value: 'Matrícula' },
            { text: 'Cliente', value: 'Cliente' },
            { text: 'Marca', value: 'Marca' },
            { text: 'Modelo', value: 'Modelo' },
            { text: 'Cód. avería', value: 'Cód_avería' },
            { text: 'Avería (Descripción)', value: 'Avería_Descripción' },
            { text: 'Solución', value: 'Solución' },
            { text: 'Acción', value: 'actions', sortable: false },
        ],
        items: [],
        dialog: false,
        editedItem: {},
        rules: [
        value => !!value || 'Requerido.',
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
        this.editedItem = item||{}
        this.dialog = !this.dialog
    },
    loadItems() {
        this.items = []
        axios.get(`https://api.airtable.com/v0/${airTableApp}/${airTableName}`,
            { headers: { Authorization: "Bearer " + apiToken }})
        .then((response) => {
            this.items = response.data.records.map((item)=>{
                return {
                    id: item.id,
                    ...item.fields
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    },
    saveItem(item) {
        /* this is used for both creating and updating API records
         the default method is POST for creating a new item */
         
        let method = "post"
        let url = `https://api.airtable.com/v0/${airTableApp}/${airTableName}`
        let id = item.id
        
        // airtable API needs the data to be placed in fields object
        let data = {
            fields: item
        }
        
        if (id) {
            // if the item has an id, we're updating an existing item
            method = "patch"
            url = `https://api.airtable.com/v0/${airTableApp}/${airTableName}/${id}`
            
            // must remove id from the data for airtable patch to work
            delete data.fields.id
        }

        // save the record
        axios[method](url,
            data,
            { headers: { 
                Authorization: "Bearer " + apiToken,
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (response.data && response.data.id) {
                console.log(response.data)
                // add new item to state
                this.editedItem.id = response.data.id
                if (!id) {
                    // add the new item to items state
                    this.items.push(this.editedItem)
                }
                this.editedItem = {}
            }
            this.dialog = !this.dialog
        })
    },
    deleteItem(item) {
        //console.log('deleteItem', item)
        let id = item.id
        let idx = this.items.findIndex(item => item.id===id)
        if (confirm('¿Estas seguro de que quieres eliminar este elemento?')) { 
            axios.delete(`https://api.airtable.com/v0/${airTableApp}/${airTableName}/${id}`,
                { headers: { 
                    Authorization: "Bearer " + apiToken,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                this.items.splice(idx, 1)
            })
            this.items.splice(idx, 1)
        }
    },
  }
}

</script>
