<template>
  <div class="reparaciones">
    <Airtable/>
  </div>
</template>

<script>
import Airtable from '../components/airtable.vue'
// @ is an alias to /src


export default {
  name: 'Reparaciones',
  components: {
    Airtable,
  }
}
</script>
